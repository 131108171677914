// React
import React, { useEffect, useState } from 'react';

// Gatsby

// Emotion / styling
import s from 'src/styles';

// Packages
import { useForm, FormProvider, useWatch } from 'react-hook-form';

// Utilities
import { useTheme, useAccount } from 'hooks';
import { useGlobal } from 'reactn';

// Components
import { PageNavigation } from 'components/pageNavigation';
import PageIntroduction from 'components/pageIntroduction';
import InputElement from 'components/_input/inputElement';
import Button from 'components/button';

const ResetPasswordPageComponent = ({ pageContext, location }) => {
    const { labels } = pageContext;

    // Hook: Account
    const { account, resetPassword, recoverPassword, loading } =
        useAccount(location);

    // Hook: useForm setup with provider for resetting
    const resetMethods = useForm();
    const { handleSubmit: resetHandleSubmit, setValue: resetSetValue } =
        resetMethods;

    // Hook: useForm setup with provider for recovering
    const recoverMethods = useForm();
    const {
        handleSubmit: recoverHandleSubmit,
        control,
        setValue: recoverSetValue,
    } = recoverMethods;

    const watchCreateNewPassword = useWatch({
        control,
        name: 'password',
    });

    // State: Reset button clicked
    const [reset, setReset] = useState(false);

    // State: Are we resetting or recovering
    const [recoverKey, setRecoverKey] = useState(null);
    const [recovered, setRecovered] = useState(false);
    const [error, setError] = useState(false);

    // Set theme
    const theme = useTheme(s.color('white'));

    // Function: Reset
    function onResetSubmit(data) {
        const { email } = data;
        resetPassword({ email }).then(() => setReset(true));
    }

    // Function: Recover
    function onRecoverSubmit(data) {
        const { password } = data;
        setError(false);
        recoverPassword({ password, reset_key: recoverKey }).then(response => {
            if (response.success) {
                recoverSetValue('password', '', {
                    shouldValidate: false,
                });
                recoverSetValue('password_confirm', '', {
                    shouldValidate: false,
                });

                setRecovered(true);
            } else {
                setError(true);
            }
        });
    }

    // Effect: Update data manually if it exists
    useEffect(() => {
        // Email
        if (account?.email) {
            resetSetValue('email', account?.email, {
                shouldValidate: true,
            });
        }
    }, [account]);

    // Effect: Set recover key based on location
    useEffect(() => {
        if (location.search) {
            setRecoverKey(location.search.split('=')[1]);
        }
    }, [location]);

    return (
        <>
            <s.layout.PageContainer>
                <div className=" pt-100 lg:pt-140">
                    <PageNavigation {...{ theme }}>
                        <span>
                            {recoverKey
                                ? labels.PAGES.RESET_PASSWORD.RECOVER_TITLE
                                : labels.PAGES.RESET_PASSWORD.RESET_TITLE}
                        </span>
                    </PageNavigation>
                    <PageIntroduction
                        {...{
                            content: recoverKey
                                ? labels.PAGES.RESET_PASSWORD
                                      .RECOVER_INTRODUCTION
                                : labels.PAGES.RESET_PASSWORD
                                      .RESET_INTRODUCTION,
                        }}
                    />
                </div>

                {!recoverKey && (
                    <FormProvider {...resetMethods}>
                        <form onSubmit={resetHandleSubmit(onResetSubmit)}>
                            <InputElement
                                {...{
                                    input: {
                                        namespace:
                                            labels.PAGES.RESET_PASSWORD.INPUTS
                                                .EMAIL,
                                        name: 'email',
                                        defaultValue: '',
                                        disabled: reset,
                                        pattern:
                                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        type: 'text',
                                        required: true,
                                    },
                                }}
                            />

                            <div className="flex flex-col items-start mt-12 space-y-12">
                                <Button
                                    {...{
                                        submit: true,
                                        fullyDisabled: reset,
                                        disabled: reset,
                                        loading,
                                        theme,
                                        label: labels.PAGES.RESET_PASSWORD
                                            .RESET,
                                        feedback:
                                            labels.PAGES.RESET_PASSWORD
                                                .RESET_COMPLETE,
                                    }}
                                />
                                {reset && (
                                    <span>
                                        {
                                            labels.PAGES.RESET_PASSWORD
                                                .RESET_COMPLETE
                                        }
                                    </span>
                                )}
                            </div>
                        </form>
                    </FormProvider>
                )}

                {recoverKey && (
                    <FormProvider {...recoverMethods}>
                        <form onSubmit={recoverHandleSubmit(onRecoverSubmit)}>
                            <InputElement
                                {...{
                                    input: {
                                        namespace:
                                            labels.PAGES.RESET_PASSWORD.INPUTS
                                                .PASSWORD,
                                        name: 'password',
                                        defaultValue: '',
                                        type: 'password',
                                        minLength: 8,
                                        required: true,
                                    },
                                }}
                            />
                            <InputElement
                                {...{
                                    input: {
                                        namespace:
                                            labels.PAGES.RESET_PASSWORD.INPUTS
                                                .PASSWORD_CONFIRM,
                                        name: 'password_confirm',
                                        defaultValue: '',
                                        type: 'password',
                                        minLength: 8,
                                        validate: value =>
                                            value === watchCreateNewPassword,
                                        required: true,
                                    },
                                }}
                            />
                            {error && (
                                <div className="flex flex-col">
                                    <p className=" text-error t-h17">
                                        {
                                            labels.PAGES.RESET_PASSWORD
                                                .RECOVER_ERROR
                                        }
                                    </p>
                                </div>
                            )}
                            <div className="flex mt-12">
                                {!error && (
                                    <Button
                                        {...{
                                            submit: true,
                                            loading,
                                            disabled: recovered,
                                            theme,
                                            label: labels.PAGES.RESET_PASSWORD
                                                .RECOVER,
                                            feedback:
                                                labels.PAGES.RESET_PASSWORD
                                                    .RECOVER_COMPLETE,
                                        }}
                                    />
                                )}
                                {error && (
                                    <Button
                                        {...{
                                            onClick() {
                                                setRecoverKey(null);
                                            },
                                            type: 'secondary',
                                            theme,
                                            label: labels.PAGES.RESET_PASSWORD
                                                .RECOVER_AGAIN,
                                        }}
                                    />
                                )}
                            </div>
                        </form>
                    </FormProvider>
                )}
            </s.layout.PageContainer>
        </>
    );
};

const NamedResetPasswordPageComponent = props => {
    return <ResetPasswordPageComponent {...props} />;
};

export default NamedResetPasswordPageComponent;
